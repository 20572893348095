<template>
  <div class="w-full">
    <progress-component></progress-component>
    <div
      class="home bg-slate-200 min-h-screen p-4 max-w-[100vw]"
      :class="{ loading }"
    >
      <div class="col-top w-full">
        <h3 class="flex justify-between items-center mb-4">
          <logo-component></logo-component>
          <input
            type="color"
            v-if="sites[currentSite]"
            :value="sites[currentSite].color"
            class="color w-[30px]"
            @change="changecolor"
            @input="changecolor"
          />
          <!-- <settings-component></settings-component> -->
        </h3>
        <profile-component
          @show_search="search = 1"
          class="mb-4 pr-10 md:pr-0"
        ></profile-component>
        <credits-component></credits-component>
        <div v-if="!isMobile || (isMobile && view == 1)" class="sites">
          <template v-if="Object.values(sites).length">
            <div
              class="site"
              v-for="(config, site) in sites"
              :key="site"
              :class="{
                posted: config.posted,
                postto: config.postTo,
              }"
            >
              <div class="site-info">
                <input
                  type="checkbox"
                  @change="saveData"
                  v-model="config.postTo"
                />
                <small
                  class="status"
                  :style="{ borderColor: config.color }"
                  :class="{
                    [`border-l-4`]: true,
                    active: currentSite == site,
                    token: config.token.length > 50,
                  }"
                  @click="getCategories(site, config, $event)"
                >
                  {{
                    site.replace("http://", "").replace("https://", "")
                  }}</small
                >
                <span class="rem" @click="removeSite(site)">x</span>
                <span
                  class="connect"
                  :class="{ active: config.token.length > 200 }"
                  @click="connect(site)"
                  ><font-awesome-icon icon="fa-solid fa-plug"
                /></span>
                <a :href="site" class="access" target="_blank"
                  ><font-awesome-icon icon="fa-solid fa-external-link"
                /></a>
                <a
                  :href="`${site}/wp-admin/admin-ajax.php?action=iaxpress_autologin&token=${config.token}&token_key=${config.token_key}`"
                  class="access"
                  target="_blank"
                  ><font-awesome-icon icon="fa-brands fa-wordpress-simple"
                /></a>
              </div>
              <div class="days">
                <div
                  class="day"
                  v-for="day in ultimos7Dias()"
                  :key="day"
                  :class="{
                    ['hasposts']: (config.counter[diaKey(day)] || 0) > 0,
                    ['error']: (config.counter[diaKey(day)] || 0) < 4,
                    ['weekend']: isWeekend(day),
                  }"
                >
                  <strong>{{ diasDaSemanaAbreviado(day) }}</strong>
                  <span>{{ config.counter[diaKey(day)] || 0 }}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="flex gap-2 justify-between w-full">
            <button class="btn btn-small" @click="addsite = true" type="button">
              adicionar
            </button>
            <button
              class="btn btn-small export"
              @click="exportsites"
              type="button"
            >
              exportar
            </button>
            <button
              class="btn btn-small import"
              @click="importsites"
              type="button"
            >
              importar
            </button>
          </div>
          <download-component class="mt-4"></download-component>
          <div class="div mt-4 instructions flex flex-col justify-between">
            <p>
              Desenvolvido por
              <a
                class="font-bold"
                href="https://ersolucoesweb.com.br"
                target="_blank"
                >ER Soluções Web</a
              >
              - v{{ version }}
            </p>
          </div>
        </div>
      </div>
      <div class="cols">
        <div
          class="w-full flex flex-col items-center mb-4 fixed top-0 left-0 h-screen p-4 md:p-8 bg-white z-50"
          v-if="search"
        >
          <button
            class="mb-4 cursor-pointer ml-auto bg-black text-white fixed top-0 right-0 text-[1.5em] w-[40px] h-[40px]"
            @click="search = 0"
          >
            X
          </button>
          <div
            class="w-full flex flex-col md:flex-row gap-4 items-center mb-4 mt-8"
          >
            <select
              v-model="fontePosts"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
            >
              <option value="">Selecione o site</option>
              <template v-for="(source, key) in sites_history" :key="source">
                <option class="py-2" :value="source.url">
                  {{ source.url }}
                </option>
              </template>
            </select>
            <div
              class="flex w-full justify-between md:w-auto gap-4 items-center"
            >
              <button
                @click="buscarPosts"
                class="whitespace-nowrap w-full md:w-auto items-center text-white bg-green-700 text-center rounded-md px-4 uppercase text-[12px] font-bold h-[40px]"
              >
                buscar posts
              </button>
              <button @click="addSource">
                <font-awesome-icon icon="fa-solid fa-plus-circle" />
              </button>
              <button @click="removeSource">
                <font-awesome-icon icon="fa-solid fa-minus-circle" />
              </button>
            </div>
          </div>
          <div
            v-if="currentSource?.is_wordpress"
            class="w-full flex-wrap md:flex-nowrap flex mb-4 gap-4 items-center justify-between"
          >
            <select
              type="text"
              @change="getFonteCats"
              v-model="remote_cat"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Palavra chave"
            >
              <option value="">{{ fonteCatText }}</option>
              <template v-for="cat in remote_cats" :key="cat.id">
                <option :value="cat.id" v-html="cat.name"></option>
              </template>
            </select>
            <input
              type="text"
              v-model="remote_search"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Palavra chave"
            />
            <input
              type="date"
              v-model="remote_date_search"
              class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
              placeholder="Data"
            />
          </div>
          <div
            class="w-full flex overflow-y-auto h-[90vh] md:h-auto flex-col justify-start items-start gap-4 pb-2 mb-4 border-b-2"
            v-if="posts.length"
          >
            <div
              class="flex w-full flex-col md:flex-row justify-between items-center"
              v-for="post in posts"
              :key="post"
            >
              <template v-if="post && post?.title">
                <span
                  @click="previewPost(post)"
                  class="text-[12px] text-normal"
                  >{{ post.date }} - {{ decode(post.title.rendered) }}</span
                >
                <div class="flex w-full md:w-auto gap-4 mt-2 md:mt-0">
                  <button
                    @click="addToList(post)"
                    :class="{
                      'text-white': uselist[post.id],
                      'text-black': !uselist[post.id],
                      'bg-green-500': uselist[post.id],
                      'bg-gray-300': !uselist[post.id],
                    }"
                    class="px-4 py-1 rounded-md text-[11px] text-center justify-center w-full md:w-auto flex items-center gap-2"
                  >
                    {{ uselist[post.id] ? "selecionado" : "selecionar" }}
                    <span
                      v-if="post.id == currentPost?.id"
                      class="w-[5px] h-[5px] rounded-[10px] bg-white block"
                    ></span>
                  </button>
                  <button
                    @click="postThis(post, 1).catch(console.log)"
                    class="px-4 py-1 rounded-md text-black bg-yellow-500 w-full md:w-auto text-[11px] text-center"
                  >
                    usar
                  </button>
                  <a
                    :href="post.link"
                    target="_blank"
                    class="px-4 py-1 cursor-pointer rounded-md text-white w-full md:w-auto bg-blue-500 text-[11px] text-center"
                    >ver</a
                  >
                </div>
              </template>
            </div>
          </div>
          <div class="flex justify-between w-full">
            <button
              v-if="Object.keys(uselist).length > 0"
              type="button"
              class="bg-yellow-400 py-1 w-full h-[50px] px-4 mt-4 text-black uppercase text-[14px] font-bold rounded-md"
              @click="postToAll"
            >
              {{
                hasSelected
                  ? "Postar nos sites selecionados"
                  : "Postar em todos os sites"
              }}
            </button>
          </div>
        </div>
        <div
          v-if="!isMobile || (isMobile && view == 3)"
          class="col-left mb-auto"
        >
          <div class="title-container gap-4" v-if="sites[currentSite]">
            <input
              class="form-control"
              v-model="form.fonte"
              type="text"
              @keyup="syncEdit"
              placeholder="Fonte"
            />
            <button
              @click="importPost"
              class="flex justify-center items-center text-[1.3em] bg-purple-600 mb-4 rounded-md text-white h-[40px] px-6"
            >
              <font-awesome-icon icon="fa-solid fa-file-import" />
            </button>
          </div>
          <div class="title-container gap-4" v-if="sites[currentSite]">
            <input
              class="form-control"
              v-model="form.title"
              type="text"
              placeholder="Título"
              @keyup="syncEdit"
            />
            <span
              id="caculate"
              @click="calculateCost"
              class="flex items-center justify-center mb-4 px-6 rounded-md h-[40px] font-bold cursor-pointer bg-yellow-400"
            >
              <span class="whitespace-nowrap">{{ cost }}</span>
            </span>
          </div>
          <div class="mb-4 w-full max-w-3xl" v-if="sites[currentSite]">
            <ckeditor class="w-full" v-if="editor && config" v-model="form.content" :modelValue="form.content" :editor="editor" :config="config" />
          </div>
          <div class="flex justify-start gap-8 text-[12px]">
            <label
              id="original-post"
              class="font-bold cursor-pointer text-red-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="usarOriginal"
                :value="true"
              />
              POSTAR ORIGINAL
            </label>
            <label
              id="draft-post"
              class="font-bold cursor-pointer text-blue-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="draft"
                :value="1"
              />
              RASCUNHO
            </label>
            <label
              id="update-post"
              class="font-bold cursor-pointer text-blue-500 mb-4 flex items-center gap-2 justify-start"
            >
              <input
                @change="saveData"
                type="checkbox"
                v-model="update_post"
                :value="true"
              />
              ATUALIZAR POST
            </label>
          </div>
          <credits-component
            v-if="!me.credits || me.credits <= 0"
          ></credits-component>
          <div
            class="w-full flex gap-4 flex-col md:flex-row items-center justify-between"
          >
            <button
              vif="me.credits > 0"
              @click="postToActive"
              type="button"
              class="bg-green-500 w-full md:w-auto h-10 py-1 px-6 mt-0 text-white p-0 uppercase text-[14px] font-bold rounded-md"
            >
              Publicar no site selecionado
            </button>
            <button
              vif="me.credits > 0"
              @click="generateIA"
              type="button"
              class="bg-blue-500 w-full md:w-auto h-10 py-1 pr-1 pl-4 mt-0 text-white p-0 uppercase text-[14px] font-bold rounded-md"
            >
              Gerar novo conteúdo com IA
              <span
                class="bg-yellow-400 text-black py-2 px-4 ml-2 rounded-md"
                >{{ cost }}</span
              >
            </button>
          </div>
        </div>
        <div
          v-if="!isMobile || (isMobile && view == 2)"
          class="col-right mb-auto"
        >
          <div class="date mb-3">
            <label class="font-bold">Data do Post</label>
            <div class="dates" v-if="sites[currentSite]">
              <input
                class="form-control mb-3"
                ref="remote_date"
                v-model="remote_date"
                type="datetime-local"
              />
            </div>
          </div>
          <div class="categorias mb-auto">
            <label class="font-bold">Categorias</label>
            <div class="cats" v-if="sites[currentSite]">
              <label
                class="cat"
                v-for="cat in sites[currentSite].categories"
                :key="cat"
              >
                <input
                  type="checkbox"
                  @change="saveData"
                  v-model="sites[currentSite].selected_categories"
                  :value="cat.id"
                />{{ cat.name }}
              </label>
            </div>
          </div>
          <div class="image" v-if="sites[currentSite]">
            <label
              class="selector"
              :style="{
                backgroundImage: `url('${image}')`,
              }"
            >
              <input
                class="file"
                type="file"
                accept="image/*"
                @change="upload"
              />
              <font-awesome-icon icon="fa-solid fa-camera" />
            </label>
            <input
              type="text"
              class="form-control mt-3"
              v-model="sites[currentSite].form.caption"
              placeholder="Legenda da Imagem"
            />
          </div>
        </div>
      </div>
      <div class="dialog" v-if="addsite">
        <form @submit.prevent="addSite">
          <input
            class="form-control"
            type="url"
            v-model="url_new_site"
            placeholder="URL do site"
          />
          <button class="btn" type="submit">CONECTAR</button
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn cancel" @click="addsite = false" type="button">
            CANCELAR
          </button>
        </form>
      </div>
      <div
        v-if="isMobile"
        class="flex flex-col bg-white absolute top-[80px] right-0"
      >
        <button
          @click="view = 1"
          :class="{ ['bg-green-600']: view == 1, ['text-white']: view == 1 }"
          class="py-2 px-3"
        >
          <font-awesome-icon icon="fa-network-wired" />
        </button>
        <button
          @click="view = 2"
          :class="{ ['bg-green-600']: view == 2, ['text-white']: view == 2 }"
          class="py-2 px-3 border-t-2"
        >
          <font-awesome-icon icon="fa-gear" />
        </button>
        <button
          @click="view = 3"
          :class="{ ['bg-green-600']: view == 3, ['text-white']: view == 3 }"
          class="py-2 px-3 border-t-2"
        >
          <font-awesome-icon icon="fa-align-left" />
        </button>
      </div>
    </div>
    <tutorial-component></tutorial-component>
    <div id="editor" ref="editor"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
import LoginComponent from "../components/LoginComponent.vue";
import mitt from "mitt";
import SettingsComponent from "../components/SettingsComponent.vue";
import { decode } from "html-entities";
import LogoComponent from "../components/LogoComponent.vue";
import DownloadComponent from "../components/DownloadComponent.vue";
import CreditsComponent from "../components/CreditsComponent.vue";
import ProfileComponent from "../components/ProfileComponent.vue";
import ProgressComponent from "../components/ProgressComponent.vue";
import TutorialComponent from "../components/TutorialComponent.vue";
import {
	ClassicEditor,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BalloonToolbar,
	BlockQuote,
	BlockToolbar,
	Bold,
	CloudServices,
	Code,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HorizontalLine,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Mention,
	PageBreak,
	Paragraph,
	PasteFromMarkdownExperimental,
	PasteFromOffice,
	RemoveFormat,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
  Base64UploadAdapter
} from "ckeditor5";
import { Ckeditor } from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";
import translations from 'ckeditor5/translations/pt-br.js';

const emitter = mitt();

export default {
  name: "HomeView",
  components: {
    ckeditor: Ckeditor,
    LoginComponent,
    SettingsComponent,
    LogoComponent,
    DownloadComponent,
    CreditsComponent,
    ProfileComponent,
    ProgressComponent,
    TutorialComponent,
  },
  data() {
    return {
      steps: [
        {
          attachTo: { element: "#sync" },
          content: { title: "Sincronize seus dados entre os dispositivos" },
        },
      ],
      search: 0,
      clipboardImage: "",
      windowWidth: window.innerWidth,
      blur: false,
      view: 1,
      saving: false,
      update_post: false,
      draft: 1,
      version,
      sites_history: [],
      tab: 0,
      currentPost: false,
      uselist: {},
      image: false,
      remote_search: "",
      remote_cat: "",
      remote_date: "",
      remote_date_search: "",
      remote_cats: [],
      focusText: false,
      fonte: "",
      fonte_selecionada: "",
      addsite: false,
      url_new_site: null,
      url: null,
      loading: false,
      caption: null,
      sites: {},
      posts: [],
      usarOriginal: false,
      fontePosts: "",
      autosaving: false,
      form: {
        cost: 0,
        post_id: 0,
        title: "",
        content: "",
        fonte: "",
        caption: "",
      },
      currentSite: false,
      activeSite: false,
      date: new Date().toLocaleDateString(),
      tracking: {},
      fonteCatText: "Categoria",
    };
  },
  watch: {
    fontePosts() {
      if (!this.search) return;
      try {
        let u = new URL(this.fontePosts);
        let _cat_url = `https://${u.hostname}/wp-json/wp/v2/categories/?_fields=id,name&per_page=100`;
        let cat_url = encodeURIComponent(_cat_url);
        this.$axios.get(`${this.proxyUrl}/?url=${cat_url}`).then((res) => {
          if (res.data.message) {
            // this.$toast.error(
            //   `Ops! Parece que ${this.fontePosts} não é um site WordPress :( \n\n${res.data.message}`
            // );
            return;
          }
          // this.$toast.success("Categorias carregadas");
          this.remote_cats = res.data;
        });
      } catch (e) {
        this.remote_cats = [];
      }
    },
    sites: {
      deep: true,
      handler() {
        this.$store.commit("setConfigs", this.$data);
      },
    },
    form: {
      deep: true,
      handler() {
        if (this.sites[this.currentSite]) {
          this.sites[this.currentSite].form = this.form;
        }
        this.$store.commit("setConfigs", this.$data);
      },
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }

    this.$axios.get("sources").then((res) => (this.sites_history = res.data));

    emitter.on("finalizado", () => {
      let posts = Object.entries(this.uselist);
      if (posts.length) {
        this.postToAll(true);
      } else {
        this.uselist = {};
        this.tracking = {};
      }
    });
    this.loadData();
    document.onpaste = (event) => {
      var items = (
        event.clipboardData ||
        event.originalEvent.clipboardData ||
        window.clipboardData
      ).items;

      for (let index in items) {
        var item = items[index];
        if (item.kind === "file") {
          event.preventDefault();
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = (event) => {
            this.clipboardImage = event.target.result;
            this.image = event.target.result;
            for (let i in this.posts) {
              if (this.currentPost.id != this.posts[i].id) {
                continue;
              }
              this.posts[i].replace_image = event.target.result;
            }
          };
          reader.readAsDataURL(blob);
          return;
        }
      }
    };
    if (this.sites[this.currentSite]) {
      this.getCategories(this.currentSite, this.sites[this.currentSite]);
    }
    window.addEventListener(
      "resize",
      () => (this.windowWidth = window.innerWidth)
    );
  },
  computed: {
    editor() {
      return ClassicEditor;
    },
    config() {
      return {
        licenseKey: "GPL", // Or 'GPL'.
        toolbar: {
          items: [
            'heading',
            'style',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'link',
            'insertTable',
            'highlight',
            'blockQuote',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent'
          ],
          shouldNotGroupWhenFull: false
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          BlockToolbar,
          Bold,
          CloudServices,
          Code,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Base64UploadAdapter,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Markdown,
          MediaEmbed,
          Mention,
          PageBreak,
          Paragraph,
          PasteFromMarkdownExperimental,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Style,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
        blockToolbar: [
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          '|',
          'link',
          'insertTable',
          '|',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent'
        ],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true
            }
          ]
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage'
          ]
        },
        initialData:
          '<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n\tYou\'ve successfully created a CKEditor 5 project. This powerful text editor\n\twill enhance your application, enabling rich text editing capabilities that\n\tare customizable and easy to use.\n</p>\n<h3>What\'s next?</h3>\n<ol>\n\t<li>\n\t\t<strong>Integrate into your app</strong>: time to bring the editing into\n\t\tyour application. Take the code you created and add to your application.\n\t</li>\n\t<li>\n\t\t<strong>Explore features:</strong> Experiment with different plugins and\n\t\ttoolbar options to discover what works best for your needs.\n\t</li>\n\t<li>\n\t\t<strong>Customize your editor:</strong> Tailor the editor\'s\n\t\tconfiguration to match your application\'s style and requirements. Or\n\t\teven write your plugin!\n\t</li>\n</ol>\n<p>\n\tKeep experimenting, and don\'t hesitate to push the boundaries of what you\n\tcan achieve with CKEditor 5. Your feedback is invaluable to us as we strive\n\tto improve and evolve. Happy editing!\n</p>\n<h3>Helpful resources</h3>\n<ul>\n\t<li>📝 <a href="https://portal.ckeditor.com/checkout?plan=free">Trial sign up</a>,</li>\n\t<li>📕 <a href="https://ckeditor.com/docs/ckeditor5/latest/installation/index.html">Documentation</a>,</li>\n\t<li>⭐️ <a href="https://github.com/ckeditor/ckeditor5">GitHub</a> (star us if you can!),</li>\n\t<li>🏠 <a href="https://ckeditor.com">CKEditor Homepage</a>,</li>\n\t<li>🧑‍💻 <a href="https://ckeditor.com/ckeditor-5/demo/">CKEditor 5 Demos</a>,</li>\n</ul>\n<h3>Need help?</h3>\n<p>\n\tSee this text, but the editor is not starting up? Check the browser\'s\n\tconsole for clues and guidance. It may be related to an incorrect license\n\tkey if you use premium features or another feature-related requirement. If\n\tyou cannot make it work, file a GitHub issue, and we will help as soon as\n\tpossible!\n</p>\n',
        language: 'pt-br',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
              ]
            }
          ]
        },
        menuBar: {
          isVisible: true
        },
        placeholder: 'Type or paste your content here!',
        style: {
          definitions: [
            {
              name: 'Article category',
              element: 'h3',
              classes: ['category']
            },
            {
              name: 'Title',
              element: 'h2',
              classes: ['document-title']
            },
            {
              name: 'Subtitle',
              element: 'h3',
              classes: ['document-subtitle']
            },
            {
              name: 'Info box',
              element: 'p',
              classes: ['info-box']
            },
            {
              name: 'Side quote',
              element: 'blockquote',
              classes: ['side-quote']
            },
            {
              name: 'Marker',
              element: 'span',
              classes: ['marker']
            },
            {
              name: 'Spoiler',
              element: 'span',
              classes: ['spoiler']
            },
            {
              name: 'Code (dark)',
              element: 'pre',
              classes: ['fancy-code', 'fancy-code-dark']
            },
            {
              name: 'Code (bright)',
              element: 'pre',
              classes: ['fancy-code', 'fancy-code-bright']
            }
          ]
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        translations: [translations]
      };
    },
    currentSource() {
      return (
        this.sites_history.filter((item) => item.url == this.fontePosts)[0] ??
        {}
      );
    },
    isMobile() {
      return this.windowWidth < 800;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.form.cost || 0);
    },
    gtag() {
      return window.gtag;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    proxyUrl() {
      return process.env.VUE_APP_PROXY_URL;
    },
    hasSelected() {
      for (var site in this.sites) {
        if (this.sites[site].postTo) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    imageUploadPlugin() {
      return (editor) => {
        // Configura o upload como Data URI
        editor.plugins.get("ImageUpload").defaultConfig = {
          // Handler para o upload de imagens como Data URI
          uploadUrl: "/upload", // Aqui você pode fornecer uma URL de upload, se necessário.
          withCredentials: false,
          headers: {
            "X-CSRF-TOKEN": "your-csrf-token", // Se você usar um token CSRF para segurança.
          },
          // Implementação personalizada para o upload da imagem
          imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                  const dataUri = reader.result;
                  resolve({
                    default: dataUri,
                  });
                };

                reader.onerror = (error) => {
                  reject(error);
                };

                reader.readAsDataURL(file); // Lê o arquivo como Data URI
              });
            },
          },
        };
      };
    },
    updateEditorContent(delta) {
      if (delta.ops && delta.ops.length)
        this.form.content = delta.ops[0].insert;
    },
    syncEdit() {
      for (let i in this.posts) {
        if (this.currentPost.id != this.posts[i].id) {
          continue;
        }
        if (this.form.title) {
          this.posts[i].replace_title = this.form.title;
        }
        if (this.form.content) {
          this.posts[i].replace_content = this.form.content;
        }
        if (this.form.fonte) {
          this.posts[i].replace_link = this.form.content;
        }
        this.currentPost = this.posts[i];
      }
    },
    previewPost(post) {
      this.currentPost = post;
      this.postThis(post, 0, 1).catch(console.log);
    },
    saveData() {
      if (this.saving) {
        clearTimeout(this.saving);
      }
      this.saving = setTimeout(() => {
        let form = Object.assign({}, this.$data);
        delete form.windowWidth;
        delete form.isMobile;
        delete form.uselist;
        delete form.editor_settings;
        delete form.version;
        delete form.loading;
        delete form.autosaving;
        delete form.saving;
        this.$axios.post("/sync", form).then(() => {
          this.loading = false;
        });
      }, 1000);
    },
    loadData() {
      return this.$axios
        .get("/sync")
        .then((res) => {
          for (let prop in res.data) {
            if (prop == "version") continue;
            this[prop] = res.data[prop];
          }
          this.$store.commit("setConfigs", res.data);
          this.loading = false;
        })
        .then(() => {
          this.$forceUpdate();

          for (let site in this.sites) {
            this.getCount(site);
          }
        });
    },
    calculateCost() {
      return this.$axios
        .post("/tokens", {
          title: this.form.title,
          content: this.form.content,
        })
        .then((res) => {
          this.form.cost = res.data.total_cost;
        });
    },
    addSource() {
      let url = prompt("Insira a URL do site");
      try {
        const fccUrl = new URL(url);
        if (this.sites_history[url]) return;
        this.$axios.post("add-source", { url }).then((res) => {
          this.sites_history = res.data;
          this.fontePosts = url;
          // this.$toast.success("Fonte adicionada");
        });
      } catch (err) {
        this.$toast.error("URL inválida");
      }
    },
    removeSource() {
      this.$axios.post("remove-source", { url: this.fontePosts }).then(() => {
        this.$toast.success("Fonte removida");
        this.fontePosts = "";
      });
    },
    importPost() {
      this.$axios
        .post(`${this.proxyUrl}/content`, { url: this.form.fonte })
        .then((res) => {
          let post = res.data;
          this.currentPost = post;
          this.postThis(post, 0, 1).catch(console.log);
        });
    },
    isWeekend(data) {
      var today = data;
      var dayOfWeek = today.getDay();

      // Check if it's Saturday (6) or Sunday (0)
      return dayOfWeek === 0 || dayOfWeek === 6;
    },
    diasDaSemanaAbreviado(data) {
      // var opcoes = { weekday: "short", locale: "pt-BR" };
      // return data.toLocaleDateString("pt-BR", opcoes);
      return data.getDate().toString().padStart(2, "0");
    },
    diaKey(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      var curDate = `${date.getFullYear()}-${month}-${day}`;
      return curDate;
    },
    ultimos7Dias() {
      var hoje = new Date(); // Obtém a data atual
      var arrayUltimos7Dias = [];

      // Itera para obter as datas dos últimos 7 dias
      for (var i = 0; i < 7; i++) {
        var data = new Date(hoje); // Cria uma nova data com a data atual
        data.setDate(hoje.getDate() - i); // Subtrai o número de dias necessários
        arrayUltimos7Dias.push(data); // Adiciona a data ao array
      }

      return arrayUltimos7Dias.reverse(); // Retorna o array de datas
    },
    addToList(post) {
      if (!this.uselist[post.id]) {
        this.uselist[post.id] = post;
      } else {
        delete this.uselist[post.id];
      }
      this.saveData();
    },
    validUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    async postThis(post, single, preview) {
      this.search = 0;
      return new Promise(async (resolve, reject) => {
        if (!this.currentSource?.is_wordpress) {
          return reject();
        }
        if (post) {
          if (post.replace_image) {
            this.image = post.replace_image;
          } else {
            if (post._embedded && post._embedded["wp:featuredmedia"]) {
              this.image = post._embedded["wp:featuredmedia"][0].source_url;
            } else {
              if (
                post.better_featured_image &&
                post.better_featured_image.source_url
              ) {
                this.image = post.better_featured_image.source_url;
              }
            }
          }
          this.tracking[post.link];
          if (post.link) {
            this.form.fonte = post.link;
          }
          if (post.replace_title) {
            this.form.title = decode(post.replace_title);
          } else {
            if (post.title && post.title.rendered) {
              this.form.title = decode(post.title.rendered);
            }
          }
          if (post.replace_content) {
            this.form.content = decode(post.replace_content);
          } else {
            if (post.content && post.content.rendered) {
              this.form.content = decode(post.content.rendered);
            }
          }
          if (post.replace_link) {
            this.form.fonte = decode(post.replace_link);
          } else {
            if (post.link) {
              this.form.fonte = post.link;
            }
          }
          let tracking_key = `${this.currentPost} ${this.form.fonte}`;
          if (this.tracking[tracking_key]) {
            return;
          }
          this.tracking[tracking_key] = true;
          this.form.post_id = post.id;
          this.calculateCost();
          this.tab = 0;
          if (single) {
            this.uselist = { [post.id]: post };
          }
          this.sites[this.currentSite].form = this.form;
          resolve();
        } else {
          reject();
        }
      });
    },
    getImage(url) {
      this.image = url;
      return new Promise((resolve) => resolve());
    },
    buscarPosts() {
      if (!this.fontePosts) return;
      if (!this.fontePosts.match(/http/gi))
        this.fontePosts = `https://${this.fontePosts}`;
      this.uselist = {};
      if (this.currentSource?.is_wordpress) {
        this.$wordpressSearch(this);
      } else {
        this.$rssSearch(this);
      }
    },
    postToActive() {
      this.genTitle().then(() => {
        this.genContent().then(() => {
          this.save()
            .then()
            .catch(() => {});
        });
      });
    },
    postToAll(stop) {
      let posts = Object.entries(this.uselist);
      if (!stop && !posts.length && this.form.title && this.form.content) {
        this.currentPost = {
          id: 0,
          link: this.form.fonte,
          title: {
            rendered: this.form.title,
          },
          content: {
            rendered: this.form.content,
          },
          _embedded: {
            "wp:featuredmedia": [
              {
                source_url: this.image,
              },
            ],
          },
        };
        posts.push(this.currentPost);
      }
      if (posts.length) {
        this.currentPost = posts.pop()[1];
        this.postThis(this.currentPost)
          .then(() => {
            setTimeout(() => {
              var sites = [];
              var _sites = [];
              for (var site in this.sites) {
                _sites.push(site);
                if (this.sites[site].postTo) {
                  sites.push(site);
                }
              }
              if (sites.length == 0) {
                sites = _sites;
              }
              this._postToAll(sites);
            }, 1000);
          })
          .catch(console.log);
      }
    },
    _postToAll(sites) {
      if (!sites.length) return;
      this.currentSite = sites.pop();
      this.genTitle().then(() => {
        this.genContent().then(() => {
          this.save()
            .then(() => {
              if (sites.length > 0) {
                this._postToAll(sites);
                emitter.emit("posted");
              } else {
                emitter.emit("finalizado");
              }
            })
            .catch(() => {});
        });
      });
    },
    updateCount(sites) {
      let current = sites.pop();
      if (current) {
        this.getCount(current).then(() => {
          this.updateCount(sites);
        });
      }
    },
    changecolor(event) {
      this.sites[this.currentSite].color = event.target.value;
      this.$forceUpdate();
      this.saveData();
      this.gtag("event", "change_color", {
        site: this.currentSite,
        color: event.target.value,
      });
    },
    exportsites() {
      const jsonString = JSON.stringify(this.$data, null, 2); // The null and 2 are for formatting the JSON
      const blob = new Blob([jsonString], { type: "application/json" });
      const downloadLink = document.createElement("a");
      downloadLink.download = `iaxpress-${new Date()
        .toLocaleString()
        .replace(/([^\d]+)/gi, "")}.json`; // Filename for the downloaded file
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.click();
      this.gtag("event", "export_sites");
    },
    importsites() {
      var input = document.createElement("input");
      input.type = "file";
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (!file) {
          return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
          const contents = event.target.result;
          try {
            const jsonData = JSON.parse(contents);
            for (let prop in jsonData) {
              this[prop] = jsonData[prop];
            }
            this.$store.commit("setConfigs", jsonData);
            setTimeout(this.saveData, 2000);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        };
        reader.readAsText(file);
      };
      input.accept = ".json";
      input.click();
      this.gtag("event", "import_sites");
    },
    insertTextAtCursor(textToInsert) {
      var textarea = this.$refs.textarea;
      var startPos = textarea.selectionStart;
      var endPos = textarea.selectionEnd;

      var textBefore = textarea.value.substring(0, startPos);
      var textAfter = textarea.value.substring(endPos, textarea.value.length);

      textarea.value = textBefore + textToInsert + textAfter;

      // Reposiciona o cursor após o texto inserido
      var newCursorPosition = startPos + textToInsert.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    },
    isImageURL(url) {
      this.gtag("event", "paste_image_url", { site: this.currentSite });
      return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(url);
    },
    isVideoURL(url) {
      this.gtag("event", "paste_video_url", { site: this.currentSite });
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    isAudioURL(url) {
      this.gtag("event", "paste_audio_url", { site: this.currentSite });
      return /\.(mp3)$/i.test(url);
    },
    upload(e) {
      let [file] = e.target.files;
      if (file) {
        var reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target.result;
          this.gtag("event", "upload", { site: this.currentSite });
        }; // data url!
        reader.readAsDataURL(file);
      }
    },
    getCount(site) {
      let now = new Date();
      let sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(now.getDate() - 7);
      let after = `${sevenDaysAgo.getFullYear()}-${(sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, 0)}-${sevenDaysAgo
        .getDate()
        .toString()
        .padStart(2, 0)}T00:00:00.000Z`.toString();
      let before = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, 0)}-${now
        .getDate()
        .toString()
        .padStart(2, 0)}T23:59:59.000Z`.toString();
      let url = `${site}wp-json/wp/v2/posts?_fields=date&per_page=50&before=${before}&after=${after}&count=1`;
      let target = encodeURI(url);
      return this.$axios.get(`${this.proxyUrl}?url=${target}`).then((res) => {
        try {
          // this.sites[site].counter_total = res.data.length;
          let posts = res.data;
          this.sites[site].counter = {};
          for (let i in posts) {
            let post = posts[i];
            let curDate = post.date.split("T").shift();
            if (!this.sites[site].counter[curDate]) {
              this.sites[site].counter[curDate] = 0;
            }
            this.sites[site].counter[curDate] =
              this.sites[site].counter[curDate] + 1;
          }
          this.$forceUpdate();
        } catch (error) {
          this.gtag("event", "error", { message: error.message });
        }
        this.gtag("event", "count", { site: site });
      });
    },
    getCategories(site, config) {
      this.currentSite = site;
      config.active = !config.active;
      this.getCount(site);
      let target = encodeURI(`${site}wp-json/wp/v2/categories?_fields=name,id`);
      this.$axios.get(`${this.proxyUrl}?url=${target}`).then((res) => {
        this.sites[site].categories = res.data;
        this.gtag("event", "get_categories", { site: site });
        this.saveData();
      });
    },
    generateIA() {
      this.loading = true;
      this.$axios
        .post("ia", { title: this.form.title, content: this.form.content })
        .then((res) => {
          this.form.title = res.data.title;
          this.form.content = res.data.content;
          this.sites[this.currentSite].form.title = res.data.title;
          this.sites[this.currentSite].form.content = res.data.content;
          this.saveData();
          this.loading = false;
          this.$axios.get("/me").then((res) => {
            this.$store.commit("setMe", res.data);
          });
          this.$forceUpdate();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    genTitle() {
      this.sites[this.currentSite].form.title = this.form.title;
      return new Promise((resolve) => resolve());
    },
    genContent() {
      this.sites[this.currentSite].form.content = this.form.content;
      return new Promise((resolve) => resolve());
    },
    verifyFonte(site) {
      if (!this.sites[site]) return;
      let form = { fonte: this.form.fonte };
      form.site = site;
      this.gtag("event", "verify_fonte", { site: site });
      return this.$axios.post(`${this.apiUrl}/verify-fonte`, form);
    },
    async save() {
      this.loading = true;
      let form = {};
      form.categories = this.sites[this.currentSite].selected_categories;
      form.title = this.form.title;
      form.content = this.form.content;
      form.caption = this.form.caption;
      form.post_id = this.form.post_id;
      form.fonte = this.form.fonte;
      form.status = this.draft ? "draft" : "publish";
      if (!this.image) {
        await new Promise((resolve, reject) => {
          setTimeout(() => resolve(), 1000);
        });
      }
      form.image = this.image;
      form.date = this.remote_date;
      form.site_url = `${this.currentSite}wp-json/iaxpress/v1/create-post`;
      form.usarOriginal = this.usarOriginal;
      form.update_post = this.update_post;
      form.token = this.sites[this.currentSite].token;
      form.token_key = this.sites[this.currentSite].token_key;
      let target = btoa(`${this.currentSite}wp-json/iaxpress/v1/create-post`);
      this.gtag("event", "save", { site: this.currentSite, post: form.title });
      delete this.uselist[form.post_id];
      return new Promise((resolve, reject) => {
        if (!this.image) {
          this.loading = false;
          resolve();
          return;
        }
        this.$axios
          .post(`${this.apiUrl}/save-post/${target}`, form)
          .then((res) => {
            this.loading = false;
            this.getCount(this.currentSite);
            this.saveData();
            if (res.data.success == false) {
              alert(res.data.message);
              reject();
            }
            this.$axios
              .get("/me")
              .then((res) => {
                this.$store.commit("setMe", res.data);
              })
              .catch(() => {
                if (localStorage.user) {
                  delete localStorage.user;
                  location.reload();
                }
              });
            resolve();
          })
          .catch(() => {
            this.loading = false;
            reject();
          });
      });
    },
    getDomain(url) {
      return new URL(url).host;
    },
    removeSite(site) {
      delete this.sites[site];
      this.saveData();
    },
    addSite() {
      let url = this.url_new_site;
      if (url) {
        localStorage.connecting = url;
        this.gtag("event", "add_site", { site: url });
        setTimeout(
          () =>
            (location.href = `${url}/wp-admin/admin-ajax.php?action=iaxpress_generate_token`),
          1000
        );
      }
    },
    decode(str) {
      return decode(str);
    },
    connect(site) {
      localStorage.connecting = site;
      this.gtag("event", "connect", { site: site });
      location.href = `${site}wp-admin/admin-ajax.php?action=iaxpress_generate_token`;
    },
  },
};
</script>
<style>
   @import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

.editor-container_include-block-toolbar {
	margin-left: 42px;
}

.ck-content h3.category {
	font-family: 'Oswald';
	font-size: 20px;
	font-weight: bold;
	color: #555;
	letter-spacing: 10px;
	margin: 0;
	padding: 0;
}

.ck-content h2.document-title {
	font-family: 'Oswald';
	font-size: 50px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	border: 0;
}

.ck-content h3.document-subtitle {
	font-family: 'Oswald';
	font-size: 20px;
	color: #555;
	margin: 0 0 1em;
	font-weight: bold;
	padding: 0;
}

.ck-content p.info-box {
	--background-size: 30px;
	--background-color: #e91e63;
	padding: 1.2em 2em;
	border: 1px solid var(--background-color);
	background: linear-gradient(
			135deg,
			var(--background-color) 0%,
			var(--background-color) var(--background-size),
			transparent var(--background-size)
		),
		linear-gradient(
			135deg,
			transparent calc(100% - var(--background-size)),
			var(--background-color) calc(100% - var(--background-size)),
			var(--background-color)
		);
	border-radius: 10px;
	margin: 1.5em 2em;
	box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
	font-family: 'Oswald';
	font-style: normal;
	float: right;
	width: 35%;
	position: relative;
	border: 0;
	overflow: visible;
	z-index: 1;
	margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
	content: '“';
	position: absolute;
	top: -37px;
	left: -10px;
	display: block;
	font-size: 200px;
	color: #e7e7e7;
	z-index: -1;
	line-height: 1;
}

.ck-content blockquote.side-quote p {
	font-size: 2em;
	line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
	font-size: 1.3em;
	text-align: right;
	color: #555;
}

.ck-content span.marker {
	background: yellow;
}

.ck-content span.spoiler {
	background: #000;
	color: #000;
}

.ck-content span.spoiler:hover {
	background: #000;
	color: #fff;
}

.ck-content pre.fancy-code {
	border: 0;
	margin-left: 2em;
	margin-right: 2em;
	border-radius: 10px;
}

.ck-content pre.fancy-code::before {
	content: '';
	display: block;
	height: 13px;
	background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
	margin-bottom: 8px;
	background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
	background: #272822;
	color: #fff;
	box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
	background: #dddfe0;
	color: #000;
	box-shadow: 5px 5px 0 #b3b3b3;
}

</style>