import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  install(app, options = {}) {
    // Configurações padrão
    const defaultOptions = {
      position: 'bottom-right', // Posição padrão dos toasts
      autoClose: 3000,      // Fecha automaticamente após 3 segundos
      pauseOnHover: true,   // Pausa o toast quando o mouse está sobre ele
      ...options,           // Permite sobrescrever as opções padrão
    };

    // Fornece o método `$toast` globalmente
    const toastMethods = {
      base: (message, opts = {}) => toast(message, { ...defaultOptions, ...opts }),
      loading: (message, opts = {}) => toast.loading(message, { ...defaultOptions, ...opts }),
      update: (message, opts = {}) => toast.update(message, { ...defaultOptions, ...opts }),
      success: (message, opts = {}) => toast.success(message, { ...defaultOptions, ...opts }),
      error: (message, opts = {}) => toast.error(message, { ...defaultOptions, ...opts }),
    };

    // Registra os métodos no globalProperties
    app.config.globalProperties.$toast = toastMethods;
  },
};
